import { readResponseAsJSON } from 'utils/fetch';
import { env } from 'env';

export interface ShortOrganizationInfo {
  organizationName: string;
  organizationLogo: string;
}

export interface RequestUploadParams {
  subject: string;
  description: string;
  applicationId: string;
}

export interface CustomerDocumentUploadApiType {
  getOrganizationInfo(token: string): Promise<ShortOrganizationInfo>;
  upload(token: string, file: File): Promise<string>;
}

export default class CustomerDocumentUploadApi implements CustomerDocumentUploadApiType {
  private origin: string = env.REACT_APP_LOS_API_URL!;

  public async getOrganizationInfo(token: string): Promise<ShortOrganizationInfo> {
    const params = new URLSearchParams();
    params.set('token', token);

    return this.fetch(`${this.origin}/applications/customer-upload/organization?${params}`, {
      method: 'GET',
    });
  }

  public async upload(token: string, file: File): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document.name', file.name);

    const params = new URLSearchParams();
    params.set('token', token);

    return this.fetch(`${this.origin}/applications/customer-upload?${params}`, {
      body: formData,
      method: 'POST',
    });
  }

  private async fetch(url: string, body: RequestInit) {
    const response = await fetch(url, body);
    return readResponseAsJSON(response);
  }
}
