import forEach from 'lodash/forEach';
import createAsyncQueue, { AsyncQueue } from 'utils/AsyncQueue';

import { readResponseAsJSON } from 'utils/fetch';

import getBasicHeaders, { ContentType } from 'utils/getBasicHeaders';

import { env } from 'env';

export type CustomHeaders = { [key: string]: string };

export interface FetchOptions<ParsedResponse = any> {
  contentType: ContentType;
  headers?: CustomHeaders;
  isBlockingRequest?: boolean;
  processResponse: ResponseParser<ParsedResponse>;
  abortSignal?: AbortSignal;
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const DEFAULT_FETCH_OPTIONS: FetchOptions = {
  contentType: 'application/json',
  processResponse: readResponseAsJSON,
};

export type RequestBody = string | { [key: string]: any } | FormData;

const stringifyRequestBody = (body?: RequestBody): string | FormData | undefined => {
  if (typeof body === 'string' || body instanceof FormData || typeof body === 'undefined') {
    return body;
  }
  return JSON.stringify(body);
};

interface ResponseParser<Result> {
  (response: Response): Promise<Result>;
}

export interface DownloadResponse {
  file: Blob;
  filename: string;
}

const FILENAME_FROM_HEADER_PATTERN = /filename="([^"]+)"/;

export type ApiMiddleware = (response: Response) => void;

class APIRest {
  private asyncQueue: AsyncQueue = createAsyncQueue();

  protected origin = env.REACT_APP_API_URL;

  constructor(protected middlewares: ApiMiddleware[]) {}

  // TODO specify ReqBody for all endpoints, then remove default type param
  protected async fetch<ResBody, ReqBody = RequestBody>(
    url: string,
    method: HttpMethod = 'GET',
    body?: ReqBody,
    options: Partial<FetchOptions> = {},
  ): Promise<ResBody> {
    const { contentType, headers: customHeaders, processResponse, isBlockingRequest, abortSignal } = {
      ...DEFAULT_FETCH_OPTIONS,
      ...options,
    };
    const headers = getBasicHeaders(contentType);

    forEach(customHeaders, (val, key) => {
      headers.set(key, val);
    });

    const requestUrl = `${this.origin}${url}`;
    const requestBody: RequestInit = {
      method,
      headers,
      body: stringifyRequestBody(body),
      signal: abortSignal,
      credentials: 'include',
    };

    if (isBlockingRequest) {
      return this.asyncQueue.enqueueBlocking<ResBody>(() => this.makeFetch(requestUrl, requestBody, processResponse));
    }

    return this.asyncQueue.enqueue<ResBody>(() => this.makeFetch(requestUrl, requestBody, processResponse));
  }

  protected async makeFetch<ResBody>(
    url: string,
    body: RequestInit,
    processResponse: ResponseParser<ResBody>,
  ): Promise<ResBody> {
    const response = await fetch(url, body);

    this.middlewares.forEach((middleware) => middleware.call(middleware, response));

    const result = await processResponse(response);

    return result;
  }

  protected download(url: string) {
    return this.fetch<DownloadResponse>(url, 'GET', undefined, {
      processResponse: async (response) => {
        const [, filename] = response.headers.get('Content-Disposition')?.match(FILENAME_FROM_HEADER_PATTERN) || [];

        if (!filename) {
          throw new Error('Filename has not been provided via the Content-Disposition header');
        }

        return {
          file: await response.blob(),
          filename,
        };
      },
    });
  }
}

export default APIRest;
