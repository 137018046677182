import APIRest from 'api/APIRest';
import { env } from 'env';

export interface CreateNewAccountInviteData {
  firstName: string;
  lastName: string;
  password: string;
}

export interface UserInviteApi {
  acceptInvite(token: string, data?: CreateNewAccountInviteData): Promise<void>;
  resendInvite: (membershipId: string) => Promise<void>;
}

export default class UserInviteApiRest extends APIRest implements UserInviteApi {
  protected origin = env.REACT_APP_CORE_API_URL;

  public async acceptInvite(token: string, data?: CreateNewAccountInviteData): Promise<void> {
    await this.fetch(`/user-invites/${token}`, 'PUT', data);
  }

  public async resendInvite(membershipId: string): Promise<void> {
    await this.fetch(`/user-invites/${membershipId}/resend`, 'POST');
  }
}
