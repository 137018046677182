import React, { FC, useState } from 'react';
import Avatar, { TextSize } from 'components/Avatar';
import styles from './UserAvatar.module.scss';
import clsx from 'clsx';
import { env } from 'env';

export interface UserAvatarProps {
  firstName?: string;
  lastName?: string;
  imageUrl?: string | null;
  imageId?: string | null;
  textClassName?: string;
  size?: TextSize;
  tooltip?: boolean;
  tooltipText?: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}

const UserAvatar: FC<UserAvatarProps> = ({
  firstName,
  lastName,
  imageUrl,
  imageId,
  size = 'medium',
  tooltip = false,
  className,
  active,
  onClick,
  textClassName,
  tooltipText,
}) => {
  const [failedToLoad, setFailedToLoad] = useState(false);
  const url = imageUrl || `${env.REACT_APP_CORE_API_URL}/assets/${imageId}`;

  return (
    <Avatar
      size={size}
      tooltip={tooltip ? tooltipText || `${firstName} ${lastName}` : undefined}
      className={clsx(styles.avatar, className, active && styles.active)}
      onClick={onClick}
    >
      {(imageUrl || imageId) && !failedToLoad ? (
        <img src={url} alt="User Avatar" className={styles.avatarImage} onError={() => setFailedToLoad(true)} />
      ) : (
        <p className={clsx(styles.avatarText, textClassName)}>
          {firstName && firstName[0]}
          {lastName && lastName[0]}
        </p>
      )}
    </Avatar>
  );
};

export default UserAvatar;
