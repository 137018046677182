import APIRest from 'api/APIRest';
import { env } from 'env';

export interface CreateAccountInformation {
  companyName: string;
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface CreateAccountData {
  accountInformation: CreateAccountInformation;
  productTemplate?: string;
  token: string;
}

export interface SignInData {
  email: string;
  password: string;
  token: string;
}

export interface AuthApi {
  signIn(data: SignInData): Promise<void>;
  selectOrganization(organizationId: string): Promise<void>;
  verifyPhoneVerificationCode(code: string): Promise<void>;
  resendPhoneVerificationCode(): Promise<void>;
  sendForgotPasswordLink(email: string): Promise<void>;
  resetPassword(password: string, token: string): Promise<void>;
  createAccount(data: CreateAccountData): Promise<void>;
  resendVerificationEmail(): Promise<void>;
  resendVerificationEmailForExpiredToken(verificationToken: string): Promise<void>;
  logout(): Promise<void>;
}

export default class AuthApiRest extends APIRest implements AuthApi {
  protected origin = env.REACT_APP_CORE_API_URL;

  public signIn(data: SignInData): Promise<void> {
    return this.fetch('/sessions', 'POST', {
      email: data.email,
      password: data.password,
      token: data.token,
    });
  }

  public selectOrganization(organizationId: string): Promise<void> {
    return this.fetch('/sessions/organization', 'PUT', { organizationId });
  }

  public verifyPhoneVerificationCode(code: string): Promise<void> {
    return this.fetch('/sessions/verify-mfa-code', 'POST', { code });
  }

  public resendPhoneVerificationCode(): Promise<void> {
    return this.fetch('/sessions/mfa-codes', 'POST');
  }

  public sendForgotPasswordLink(email: string): Promise<void> {
    return this.fetch('/password-reset-requests', 'POST', { email });
  }

  public resetPassword(password: string, token: string): Promise<void> {
    return this.fetch('/user-password', 'PUT', { password, token });
  }

  public createAccount(data: CreateAccountData): Promise<void> {
    return this.fetch('/organizations', 'POST', {
      companyName: data.accountInformation.companyName,
      country: data.accountInformation.country,
      firstName: data.accountInformation.firstName,
      lastName: data.accountInformation.lastName,
      email: data.accountInformation.email,
      password: data.accountInformation.password,
      productType: data.productTemplate,
      token: data.token,
    });
  }

  public resendVerificationEmail(): Promise<void> {
    return this.fetch('/email-confirmation-links', 'POST');
  }

  public resendVerificationEmailForExpiredToken(verificationToken: string) {
    return this.fetch<void>(`/email-confirmation-links/expired`, 'POST', {
      verificationToken,
    });
  }

  public logout(): Promise<void> {
    return this.fetch('/sessions', 'DELETE');
  }
}
