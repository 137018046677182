import APIRest from './APIRest';
import { env } from 'env';

const REST_API = {
  GENERATE_FIRST_SECRET: '/client/first-secret-key',
  GENERATE_SECOND_SECRET: '/client/second-secret-key',
};

export interface AuthenticationAPI {
  generateFirstSecret(): Promise<void>;
  generateSecondSecret(): Promise<void>;
}

export default class AuthenticationAPIRest extends APIRest implements AuthenticationAPI {
  protected origin = env.REACT_APP_CORE_API_URL;

  async generateFirstSecret(): Promise<void> {
    return this.fetch<void>(REST_API.GENERATE_FIRST_SECRET, 'PATCH');
  }

  async generateSecondSecret(): Promise<void> {
    return this.fetch<void>(REST_API.GENERATE_SECOND_SECRET, 'PATCH');
  }
}
