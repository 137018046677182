import APIRest from './APIRest';
import { DownloadAPI } from './Types';
import { env } from 'env';

const DOWNLOAD_API = {
  DOWNLOAD_API_TEMPLATE_URL: '/api-request-templates/strategies',
  DOWNLOAD_BATCH_PROCESS_TEMPLATE: '/batch-processing-templates',
};

class DownloadAPIRest extends APIRest implements DownloadAPI {
  protected origin = env.REACT_APP_DE_API_URL;

  protected async getCommonParams(): Promise<URLSearchParams> {
    const params = new URLSearchParams();
    params.set('export', 'file');
    return params;
  }

  async downloadApiTemplateURL(strategyId: string): Promise<void> {
    const url = `${this.origin}${DOWNLOAD_API.DOWNLOAD_API_TEMPLATE_URL}/${strategyId}`;
    window.open(url);
  }

  downloadRulesTemplate(moduleType: string, strategyId: string, moduleKey: string, branchIndex: number): void {
    const params = new URLSearchParams();
    params.set('type', moduleType);
    params.set('format', 'json');
    params.set('export', 'file');
    params.set('moduleId', moduleKey);
    params.set('segment', branchIndex.toString());

    const url = `${env.REACT_APP_DE_API_URL}/decision/api/strategies/${strategyId}/downloads/rules?${params}`;
    window.open(url);
  }

  async downloadBatchTemplate(strategyId: string): Promise<void> {
    const url = `${this.origin}${DOWNLOAD_API.DOWNLOAD_BATCH_PROCESS_TEMPLATE}?strategy-id=${strategyId}`;
    window.open(url);
  }
}

export default DownloadAPIRest;
