import React from 'react';
import clsx from 'clsx';
import styles from './Title.module.scss';
import ShareButton from 'components/BillingDashboard/ShareButton';
import { AppRoutes } from 'routes/RouteService';
import PageName from 'constants/PageName';
import { env } from 'env';

interface TitleProps {
  className?: string;
  hidePaymentPortalLink: boolean;
}

const Title = ({ hidePaymentPortalLink, className }: TitleProps) => {
  const openPaymentPortal = () => window.open(`${env.REACT_APP_CORE_API_URL}${AppRoutes.CustomerPortal}`);

  return (
    <div className={clsx(styles.container, className)}>
      <h2 className={styles.title}>{PageName.PlanAndBilling}</h2>
      {!hidePaymentPortalLink && <ShareButton onClick={openPaymentPortal}>Payment Portal</ShareButton>}
    </div>
  );
};

export default Title;
