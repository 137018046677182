import CoreSystemApi from 'api/Core/CoreSystemApi';
import { OrganizationInfo } from 'api/Core/OrganizationApi';
import { env } from 'env';

interface GetUserOrganizationParams {
  includeLogo?: boolean;
}

export interface OrganizationMembershipApi {
  getUserOrganizations: (params: GetUserOrganizationParams) => Promise<OrganizationInfo[]>;
}

export default class OrganizationMembershipApiRest extends CoreSystemApi<OrganizationInfo>
  implements OrganizationMembershipApi {
  protected origin = env.REACT_APP_CORE_API_URL;

  protected resourceName = 'organization-memberships';

  public getUserOrganizations(params: GetUserOrganizationParams) {
    const urlParams = new URLSearchParams();
    urlParams.set('include-logo', params.includeLogo ? 'true' : 'false');
    return this.getResources(urlParams);
  }
}
