import React, { FC } from 'react';
import styles from './SeatsAreFullPopUp.module.scss';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import ShareButton from 'components/BillingDashboard/ShareButton';
import { AppRoutes } from 'routes/RouteService';
import { env } from 'env';

interface SeatsAreFullPopUpProps {
  onPopupClose: () => void;
  maxSeats: number;
  currentSeats: number;
}

const SeatsAreFullPopUp: FC<SeatsAreFullPopUpProps> = ({ onPopupClose, maxSeats, currentSeats }) => {
  return (
    <PopUp title="Your Seats Are Full" closePopUp={onPopupClose}>
      <PopUpContent hasTopMargin>
        <p className={styles.description}>
          You can’t activate this user account because you are currently using{' '}
          <span className={styles.seatsCount}>
            {currentSeats} of {maxSeats}
          </span>{' '}
          purchased seats. Please add more seats to continue.
        </p>
        <div className={styles.buttonsContainer}>
          <ShareButton kind="primary" size="form" onClick={() => window.open(`${env.REACT_APP_CORE_API_URL}${AppRoutes.CustomerPortal}`)}>
            Add Seats
          </ShareButton>
          <Button kind="secondary" size="form" className={styles.goBackButton} onClick={onPopupClose}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default SeatsAreFullPopUp;
