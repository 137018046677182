import APIRest from './APIRest';

import { OrganizationApiType, UserAddResult } from './Types';
import {
  OrganizationInfoType,
  UpdateOrganizationInfoParams,
  UpdateRegionSettingsRequestData,
} from 'CompanyInformation/CompanyInformationTypes';
import { AddNewUserParams, NewUserType } from 'AddNewCompanyUser/AddNewCompanyUserTypes';
import toBase64 from 'utils/toBase64';
import { env } from 'env';

const REST_API = {
  GET_TRANSACTIONS: '/payment/getTransactions?paginate=true&',
  GET_MONTH_TRANSACTIONS: '/payment/getCustomer?getMonthTransactions=true&',
  ADD_NEW_USER: '/users',
  UPDATE_COMPANY_USER_INFO: '/user/update_user/',
  UPDATE_ORGANIZATION_INFO: '/organization/organization-info',
  UPDATE_ORGANIZATION_LOGO: '/organization/logo',
  UPDATE_ORGANIZATION_REGION_SETTINGS: '/organization/region-settings',
};

const ERROR_MESSAGE_NO_SEATS_AVAILABLE = 'No seats available.';

class Organization extends APIRest implements OrganizationApiType {
  protected origin = env.REACT_APP_CORE_API_URL;

  async updateOrganizationInfo(organizationInfo: UpdateOrganizationInfoParams): Promise<OrganizationInfoType> {
    const { legalAddress, country, primaryContact } = organizationInfo;
    const requestBody = {
      legalAddress,
      country,
      primaryContact,
    };

    return this.fetch<OrganizationInfoType>(REST_API.UPDATE_ORGANIZATION_INFO, 'PUT', requestBody);
  }

  async addNewUser(userParams: AddNewUserParams) {
    try {
      await this.fetch<NewUserType>(REST_API.ADD_NEW_USER, 'POST', {
        email: userParams.username,
        permissionGroupId: userParams.permissionGroupId,
      });
      return UserAddResult.SuccessfullyAdded;
    } catch (error) {
      if (error.message === ERROR_MESSAGE_NO_SEATS_AVAILABLE) {
        return UserAddResult.NoSeatsAvailable;
      }
      throw error;
    }
  }

  async updateOrganizationLogo(logo: File) {
    const base64Logo = await toBase64(logo);

    const requestData = {
      organizationLogo: base64Logo,
    };
    return this.fetch<OrganizationInfoType>(REST_API.UPDATE_ORGANIZATION_LOGO, 'PUT', requestData);
  }

  updateRegionSettings(regionSettingsData: UpdateRegionSettingsRequestData) {
    return this.fetch<OrganizationInfoType>(REST_API.UPDATE_ORGANIZATION_REGION_SETTINGS, 'PUT', regionSettingsData);
  }
}

export default Organization;
