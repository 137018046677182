declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_API_URL: string;
  REACT_APP_LOS_API_URL: string;
  REACT_APP_DE_API_URL: string;
  REACT_APP_CORE_API_URL: string;
  REACT_APP_WEBHOOKS_API_URL: string;
  EXTEND_ESLINT: string;
  REACT_APP_API_VERSION: string;
  REACT_APP_ENTITY_TYPE: string;
  REACT_APP_EMAIL_PARSE_DOMAIN: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_CAPTCHA_KEY: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_TRACKING_SYSTEM: string;
  REACT_APP_INSIGHTS_INSTRUMENTATION_KEY: string;
  REACT_APP_DISABLE_CREATE_ACCOUNT: string;
  REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY: string;
};
export const env: EnvType = { ...process.env, ...window.env };
