import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import AppRoutes from 'routes';
import store, {
  history,
  decisionEngineApi,
  variablesApi,
  downloadApi,
  labelsApi,
  organizationCoreApi,
  authHandler,
  decisionResultApi,
  eventTypeOptionsApi,
} from 'stores';
import {
  applicationDocumentsApi,
  loanOriginationSystemProductsApi,
  loanOriginationSystemEmailsApi,
  eSignTemplatesApi,
  loanOriginationSystemBorrowersApi,
  loanOriginationSystemIntermediariesApi,
  simplifiedApplicationsApi,
  documentFoldersApi,
  applicationTabsApi,
  productCalculationsApi,
} from 'stores/loanOriginationSystem';
import ErrorBoundary from 'components/ErrorBoundary';
import Dev from './Dev/Dev';
import ReactGA from 'react-ga';

import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'animate.css/animate.css';
import 'semantic-ui-css/semantic.min.css';
import 'styles/basic.scss';

import './index.scss';
import DebouncingAccumulator from 'utils/DebouncingAccumulator';
import { loadVariables } from 'VariablesAttributes/Actions';
import { VariablesRequesterContextProvider } from 'hooks/useVariableDataType';
import ApiProvider, { ApiProviderValue } from './providers/ApiServiceProvider';
import AuthProvider from 'providers/AuthProvider';
import AppInitializer from 'AppInitializer';
import CaptchaProvider from 'CaptchaProvider';
import { subscribeToHistoryChange } from 'RedirectionsMiddleware';
import { LayoutMountPoint } from 'layout';
import GlobalOverlays from 'pages/Main';
import { Router } from 'react-router';
import { VariablesBySystemNameLoader } from 'Variables/VariablesBySystemNameLoader';
import { setIsLoading } from 'SessionInfo/ActionCreator';
import initializeTrackingSystem from './initializeTrackingSystem';
import { env } from 'env';

initializeTrackingSystem();

const trackingId = env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

if (trackingId) {
  ReactGA.initialize(trackingId);
}

const apiProviderValue: ApiProviderValue = {
  downloadApi,
  decisionEngineApi,
  variablesApi,
  productsApi: loanOriginationSystemProductsApi,
  applicationDocumentsApi,
  decisionResultApi,
  emailsApi: loanOriginationSystemEmailsApi,
  eSignTemplatesApi,
  labelsApi,
  organizationApi: organizationCoreApi,
  eventTypeOptionsApi,
  simplifiedApplicationsApi,
  borrowersApi: loanOriginationSystemBorrowersApi,
  intermediariesApi: loanOriginationSystemIntermediariesApi,
  documentFoldersApi,
  applicationTabsApi,
  productCalculationsApi,
};

const variableRequester = DebouncingAccumulator(async (variableIds: string[]) => {
  store.dispatch(loadVariables({ ids: variableIds }));
  return [] as void[];
});

store.dispatch(setIsLoading(authHandler.isLoggedIn()));

ReactDOM.render(
  <Router history={history}>
    <ErrorBoundary>
      {/* @ts-ignore */}
      <Provider store={store}>
        <CaptchaProvider>
          <AuthProvider value={authHandler}>
            <VariablesRequesterContextProvider value={variableRequester}>
              <VariablesBySystemNameLoader>
                <ApiProvider value={apiProviderValue}>
                  <LayoutMountPoint>
                    <AppInitializer>
                      <AppRoutes />
                      {process.env.NODE_ENV === 'production' ? null : <Dev />}
                    </AppInitializer>
                  </LayoutMountPoint>
                  <GlobalOverlays />
                </ApiProvider>
              </VariablesBySystemNameLoader>
            </VariablesRequesterContextProvider>
          </AuthProvider>
        </CaptchaProvider>
      </Provider>
    </ErrorBoundary>
  </Router>,
  document.getElementById('root'),
  /**
   * It is important to subscribe to history changes after ReactRouter does,
   * otherwise synchronous calls to push()/replace() from the history.listen callback are silently swallowed
   * without triggering a re-render which leads to the UI state not being consistent with the address bar.
   */
  () => store.dispatch(subscribeToHistoryChange()),
);
