import Feature from 'BillingPage/Feature';
import InvoiceStatus from 'BillingPage/InvoiceStatus';
import APIRest from './APIRest';
import { DataViewSortingType } from './Types';
import { env } from 'env';

export type FeaturesConfig = {
  [key in Feature]: boolean;
};

export interface ProductsConfig {
  decisionEngine: boolean;
  loadOriginationSystem: boolean;
  digitalLendingPortal: boolean;
}

export interface BillingInfo {
  displayPrice: string;
  licenseTier: string;
  seats: number;
  applicationsIncluded: number;
  decisionsIncluded: number;
  extraApplicationPrice: string;
  extraDecisionPrice: string;
  features: FeaturesConfig;
  products: ProductsConfig;
  reportDate: Date;
  trialEndDate: Date | null;
  professionalServicesRate: string;
  amountOwed: number | null;
  currency: string | null;
}

export interface Invoice {
  invoiceDate: Date;
  dueDate: Date;
  description: string;
  invoiceNumber: string;
  amount: number;
  status: InvoiceStatus;
  linkToPdf: string | null;
  linkToDetails: string | null;
  currency: string | null;
}

export enum InvoicesSortingField {
  InvoiceDate = 'invoiceDate',
  DueDate = 'dueDate',
  Description = 'description',
  InvoiceNumber = 'invoiceNumber',
  Amount = 'amount',
  Status = 'status',
}

export interface BillingApi {
  getBillingInfo(): Promise<BillingInfo>;
  getInvoices(sortingType: DataViewSortingType<InvoicesSortingField>): Promise<Invoice[]>;
}

export default class BillingRestApi extends APIRest implements BillingApi {
  protected origin = env.REACT_APP_CORE_API_URL;

  public getBillingInfo() {
    return this.fetch<BillingInfo>('/plan-and-billing');
  }

  public async getInvoices(sortingType: DataViewSortingType<InvoicesSortingField>): Promise<Invoice[]> {
    const params = new URLSearchParams();

    params.set('sortField', sortingType.field);
    params.set('sortDirection', sortingType.ascending ? 'asc' : 'desc');

    return this.fetch<Invoice[]>(`/plan-and-billing/invoices?${params}`);
  }
}
