import APIRest from 'api/APIRest';
import {
  CountInformationResult,
  LoanOriginationSystemReportingFilters,
  mapTotalCount,
  ReportingDashboardChart,
  StatusChangeInformationResult,
  mapData,
  mapCrossSellingInformation,
  ApplicationCountInformationResult,
} from './ReportingMappers';
import ReportingDashboardChartName from 'LoanOriginationSystemReporting/ReportingDashboardChartName';
import moment from 'moment';
import { env } from 'env';

export type ReportingDashboardData = {
  [key in ReportingDashboardChartName]?: ReportingDashboardChart;
};

export interface LoanOriginationSystemReportingApi {
  getAllData(filters: LoanOriginationSystemReportingFilters): Promise<ReportingDashboardData>;
}

export default class LoanOriginationSystemReportingRestApi extends APIRest
  implements LoanOriginationSystemReportingApi {
  protected origin = env.REACT_APP_LOS_API_URL;

  public async getAllData(filters: LoanOriginationSystemReportingFilters): Promise<ReportingDashboardData> {
    const params = this.getUrlParamsByFilter(filters);
    const totalCountParams = this.getUrlParamsForTotalCountFilter(filters);

    const applicationCountInformation = await this.getApplicationCountInformation(params);
    const approvedApplicationCountInformation = await this.getApplicationCountInformation(params, true);
    const applicationTotalCount = await this.getApplicationTotalCount(totalCountParams);
    const borrowerCountInformation = await this.getBorrowerCountInformation(params);
    const borrowerTotalCount = await this.getBorrowerTotalCount(totalCountParams);
    const intermediaryCountInformation = await this.getIntermediaryCountInformation(params);
    const intermediaryTotalCount = await this.getIntermediaryTotalCount(totalCountParams);
    const applicationStatusChangeInformation = await this.getApplicationStatusChangeInformation(params);

    return {
      [ReportingDashboardChartName.ApplicationLoanAmountTotal]: mapData(
        applicationCountInformation.totalLoanAmount,
        applicationCountInformation.groups,
        filters,
        'loanAmount',
      ),
      [ReportingDashboardChartName.ApplicationNumberTotal]: mapData(
        applicationCountInformation.totalCount,
        applicationCountInformation.groups,
        filters,
        'count',
      ),
      [ReportingDashboardChartName.ApprovedApplicationsLoanAmountTotal]: mapData(
        approvedApplicationCountInformation.totalLoanAmount,
        approvedApplicationCountInformation.groups,
        filters,
        'loanAmount',
      ),
      [ReportingDashboardChartName.ApprovedApplicationsNumberTotal]: mapData(
        approvedApplicationCountInformation.totalCount,
        approvedApplicationCountInformation.groups,
        filters,
        'count',
      ),
      [ReportingDashboardChartName.BorrowerNumberTotal]: mapTotalCount(
        borrowerCountInformation,
        borrowerTotalCount,
        filters,
      ),
      [ReportingDashboardChartName.NewBorrowerNumberTotal]: mapData(
        borrowerCountInformation.totalCount,
        borrowerCountInformation.groups,
        filters,
        'count',
      ),
      [ReportingDashboardChartName.IntermediaryNumberTotal]: mapTotalCount(
        intermediaryCountInformation,
        intermediaryTotalCount,
        filters,
      ),
      [ReportingDashboardChartName.NewIntermediaryNumberTotal]: mapData(
        intermediaryCountInformation.totalCount,
        intermediaryCountInformation.groups,
        filters,
        'count',
      ),
      [ReportingDashboardChartName.ApplicationLoanAverage]: mapData(
        applicationCountInformation.totalLoanAmountAverage,
        applicationCountInformation.groups,
        filters,
        'loanAmountAverage',
      ),
      [ReportingDashboardChartName.ApprovedApplicationLoanAverage]: mapData(
        approvedApplicationCountInformation.totalLoanAmountAverage,
        approvedApplicationCountInformation.groups,
        filters,
        'loanAmountAverage',
      ),
      [ReportingDashboardChartName.AverageDaysToApproval]: mapData(
        applicationStatusChangeInformation.totalAverageTimeToApprove,
        applicationStatusChangeInformation.groups,
        filters,
        'averageTimeToApprove',
      ),
      [ReportingDashboardChartName.AverageDaysToRejection]: mapData(
        applicationStatusChangeInformation.totalAverageTimeToReject,
        applicationStatusChangeInformation.groups,
        filters,
        'averageTimeToReject',
      ),
      [ReportingDashboardChartName.CloseRate]: mapData(
        applicationStatusChangeInformation.totalCloseRate,
        applicationStatusChangeInformation.groups,
        filters,
        'closeRate',
      ),
      [ReportingDashboardChartName.RejectionRate]: mapData(
        applicationStatusChangeInformation.totalRejectionRate,
        applicationStatusChangeInformation.groups,
        filters,
        'rejectionRate',
      ),
      [ReportingDashboardChartName.ApplicationsPerBorrower]: mapCrossSellingInformation(
        applicationCountInformation,
        borrowerCountInformation,
        applicationTotalCount,
        borrowerTotalCount,
        filters,
      ),
      [ReportingDashboardChartName.ApplicationsPerIntermediary]: mapCrossSellingInformation(
        applicationCountInformation,
        intermediaryCountInformation,
        applicationTotalCount,
        intermediaryTotalCount,
        filters,
      ),
    };
  }

  private async getApplicationCountInformation(params: URLSearchParams, approvedOnly: boolean = false) {
    if (approvedOnly) {
      params.append('approvedOnly', 'true');
    }

    return this.fetch<ApplicationCountInformationResult>(`/application-reporting/count-information?${params}`);
  }

  private async getApplicationStatusChangeInformation(params: URLSearchParams) {
    return this.fetch<StatusChangeInformationResult>(`/application-reporting/status-change-information?${params}`);
  }

  private async getBorrowerCountInformation(params: URLSearchParams) {
    return this.fetch<CountInformationResult>(`/borrower-reporting/count-information?${params}`);
  }

  private async getBorrowerTotalCount(params: URLSearchParams) {
    const { count } = await this.fetch(`/borrower-reporting/total-count?${params}`);

    return count;
  }

  private async getIntermediaryCountInformation(params: URLSearchParams) {
    return this.fetch<CountInformationResult>(`/intermediary-reporting/count-information?${params}`);
  }

  private async getIntermediaryTotalCount(params: URLSearchParams) {
    const { count } = await this.fetch(`/intermediary-reporting/total-count?${params}`);

    return count;
  }

  private async getApplicationTotalCount(params: URLSearchParams) {
    const { count } = await this.fetch(`/application-reporting/total-count?${params}`);

    return count;
  }

  private getUrlParamsForTotalCountFilter(filters: LoanOriginationSystemReportingFilters) {
    const params = this.getUrlParamsByFilter(filters);

    params.delete('dateFrom');
    params.set(
      'dateTo',
      moment(filters.from)
        .subtract({ day: 1 })
        .endOf('day')
        .toDate()
        .toISOString(),
    );

    return params;
  }

  private getUrlParamsByFilter(filters: LoanOriginationSystemReportingFilters) {
    const params = new URLSearchParams();

    params.append('dateFrom', filters.from.toISOString());
    params.append('dateTo', filters.to.toISOString());
    params.append('period', filters.period.toLowerCase());
    params.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    if (filters.intermediaryId) {
      params.append('intermediaryId', filters.intermediaryId);
    }

    if (filters.organizationMemberId) {
      params.append('organizationMemberId', filters.organizationMemberId);
    }

    if (filters.productId) {
      params.append('productId', filters.productId);
    }

    return params;
  }
}
